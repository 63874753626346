.loader {
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 32px 0 #fff, -32px 0 #fff;
	position: relative;
	animation: flash 2s ease-out infinite alternate;
	opacity: 50%;
}

@keyframes flash {
	0% {
		background-color: #fff2;
		box-shadow: 32px 0 #fff2, -32px 0 #fff;
	}
	50% {
		background-color: #fff;
		box-shadow: 32px 0 #fff2, -32px 0 #fff2;
	}
	100% {
		background-color: #fff2;
		box-shadow: 32px 0 #fff, -32px 0 #fff2;
	}
}
