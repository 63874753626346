@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
	font-family: 'flamenco';
	src: url('./assets/fonts/Flamenco-Regular.ttf');
}

* {
	overflow: hidden;
	scroll-behavior: smooth;
	flex-shrink: 0;
	overscroll-behavior-y: contain;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@layer base {
	/* Estilos de la barra de desplazamiento */
	::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #bbb;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: #999;
	}

	/* Estilos de la barra de desplazamiento en Firefox */
	* {
		scrollbar-width: thin;
		scrollbar-color: #cecece28 #d4d4d421;
	}

	*::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}

	*::-webkit-scrollbar-thumb {
		background-color: #cecece6e;
	}

	*::-webkit-scrollbar-thumb:hover {
		background-color: #d4d4d452;
	}

	.gradient-1 {
		background-image: linear-gradient(
			to right,
			transparent,
			rgba(0, 0, 0, 0.75) 50%,
			transparent
		);
	}

	.text-shadow-1 {
		text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
	}
	.text-shadow-2 {
		text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
	}
	.text-shadow-3 {
		text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7);
	}
	.text-shadow-4 {
		text-shadow: 4px 4px 5px rgba(0, 0, 0, 1);
	}
	.text-shadow-5 {
		text-shadow: 10px 15px 10px rgba(0, 0, 0, 1);
	}
	.text-shadow-6 {
		text-shadow: 0px -8px 7px rgba(0, 0, 0, 0.4);
	}
	.text-shadow-7 {
		text-shadow: 0px 0px 8px rgb(250 250 0 / 0.7);
	}

	.box-shadow-1 {
		box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.5);
	}
	.box-shadow-2 {
		box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.6);
	}
	.box-shadow-3 {
		box-shadow: 6px 6px 7px 2px rgba(0, 0, 0, 0.7);
	}

	.animation-right-to-left {
		position: relative;
		animation-name: right-to-left;
		animation-duration: 20s;
		animation-fill-mode: forwards;
		animation-timing-function: ease-in-out;
	}

	@keyframes right-to-left {
		from {
			left: 20px;
		}
		to {
			left: -20px;
		}
	}

	.animation-left-to-right {
		position: relative;
		animation-name: left-to-right;
		animation-duration: 20s;
		animation-fill-mode: forwards;
		animation-timing-function: ease-in-out;
	}

	@keyframes left-to-right {
		from {
			right: 20px;
		}
		to {
			right: -30px;
		}
	}
}

.toast-in {
	animation-name: toast-in;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;
}

@keyframes toast-in {
	from {
		transform: translateY(100%);
	}
	to {
		transform: translateY(0);
	}
}

.toast-out {
	animation-name: toast-out;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-timing-function: ease-in-out;
}

@keyframes toast-out {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(100%);
	}
}

.mobileMenu-in {
	animation-name: mobileMenu-in;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@keyframes mobileMenu-in {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0);
	}
}

.mobileMenu-out {
	animation-name: mobileMenu-out;
	animation-duration: 0.5s;
	animation-fill-mode: forwards;
	animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@keyframes mobileMenu-out {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100%);
	}
}
